<template>
  <div id="advisoryNotice">
    <el-dialog
      :title="advisoryNoticeFormTitle"
      width="1200px"
      :visible.sync="advisoryNoticeDialogVisible"
      :close-on-click-modal="false"
      @close="advisoryNoticeDialogClose"
    >
      <el-form
        ref="advisoryNoticeFormRef"
        :model="advisoryNoticeForm"
        :rules="advisoryNoticeFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="advisoryNoticeForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格型号" prop="spec">
              <el-input v-model="advisoryNoticeForm.spec" placeholder="请输入规格型号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生产批号" prop="batchNo">
              <el-input v-model="advisoryNoticeForm.batchNo" placeholder="请输入生产批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input v-model="advisoryNoticeForm.quantity" placeholder="请输入数量" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="发布原因" prop="reason">
              <el-input
                v-model="advisoryNoticeForm.reason"
                placeholder="请输入发布原因"
                type="textarea"
                :rows="5"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="可能产生的危害" prop="harm">
              <el-input
                v-model="advisoryNoticeForm.harm"
                placeholder="请输入可能产生的危害"
                type="textarea"
                :rows="5"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="纠正和预防措施" prop="measures">
              <el-input
                v-model="advisoryNoticeForm.measures"
                placeholder="请输入纠正和预防措施"
                type="textarea"
                :rows="5"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批准" prop="approver">
              <el-input v-model="advisoryNoticeForm.approver" placeholder="请输入批准" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="审核" prop="auditor">
              <el-input v-model="advisoryNoticeForm.auditor" placeholder="请输入审核" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="制定" prop="editor">
              <el-input v-model="advisoryNoticeForm.editor" placeholder="请输入制定" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="approveDate">
              <el-date-picker v-model="advisoryNoticeForm.approveDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="auditDate">
              <el-date-picker v-model="advisoryNoticeForm.auditDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="editDate">
              <el-date-picker v-model="advisoryNoticeForm.editDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="advisoryNoticeDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="advisoryNoticeFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="advisoryNoticePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="batchNo" label="生产批号" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="reason" label="发布原因" />
      <el-table-column prop="harm" label="可能产生的危害" />
      <el-table-column prop="measures" label="纠正和预防措施" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="advisoryNoticePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addAdvisoryNotice, deleteAdvisoryNotice, updateAdvisoryNotice, selectAdvisoryNoticeInfo, selectAdvisoryNoticeList } from '@/api/universal/advisoryNotice'

export default {
  data () {
    return {
      advisoryNoticeDialogVisible: false,
      advisoryNoticeFormTitle: '',
      advisoryNoticeForm: {
        id: '',
        productName: '',
        spec: '',
        batchNo: '',
        quantity: '',
        reason: '',
        harm: '',
        measures: '',
        editor: '',
        editDate: '',
        auditor: '',
        auditDate: '',
        approver: '',
        approveDate: ''
      },
      advisoryNoticeFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      advisoryNoticePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectAdvisoryNoticeList(this.searchForm).then(res => {
      this.advisoryNoticePage = res
    })
  },
  methods: {
    advisoryNoticeDialogClose () {
      this.$refs.advisoryNoticeFormRef.resetFields()
    },
    advisoryNoticeFormSubmit () {
      if (this.advisoryNoticeFormTitle === '忠告性通知发布记录详情') {
        this.advisoryNoticeDialogVisible = false
        return
      }
      this.$refs.advisoryNoticeFormRef.validate(async valid => {
        if (valid) {
          if (this.advisoryNoticeFormTitle === '新增忠告性通知发布记录') {
            await addAdvisoryNotice(this.advisoryNoticeForm)
          } else if (this.advisoryNoticeFormTitle === '修改忠告性通知发布记录') {
            await updateAdvisoryNotice(this.advisoryNoticeForm)
          }
          this.advisoryNoticePage = await selectAdvisoryNoticeList(this.searchForm)
          this.advisoryNoticeDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.advisoryNoticeFormTitle = '新增忠告性通知发布记录'
      this.advisoryNoticeDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteAdvisoryNotice(row.id)
        if (this.advisoryNoticePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.advisoryNoticePage = await selectAdvisoryNoticeList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.advisoryNoticeFormTitle = '修改忠告性通知发布记录'
      this.advisoryNoticeDialogVisible = true
      this.selectAdvisoryNoticeInfoById(row.id)
    },
    handleInfo (index, row) {
      this.advisoryNoticeFormTitle = '忠告性通知发布记录详情'
      this.advisoryNoticeDialogVisible = true
      this.selectAdvisoryNoticeInfoById(row.id)
    },
    selectAdvisoryNoticeInfoById (id) {
      selectAdvisoryNoticeInfo(id).then(res => {
        this.advisoryNoticeForm.id = res.id
        this.advisoryNoticeForm.productName = res.productName
        this.advisoryNoticeForm.spec = res.spec
        this.advisoryNoticeForm.batchNo = res.batchNo
        this.advisoryNoticeForm.quantity = res.quantity
        this.advisoryNoticeForm.reason = res.reason
        this.advisoryNoticeForm.harm = res.harm
        this.advisoryNoticeForm.measures = res.measures
        this.advisoryNoticeForm.editor = res.editor
        this.advisoryNoticeForm.editDate = res.editDate
        this.advisoryNoticeForm.auditor = res.auditor
        this.advisoryNoticeForm.auditDate = res.auditDate
        this.advisoryNoticeForm.approver = res.approver
        this.advisoryNoticeForm.approveDate = res.approveDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectAdvisoryNoticeList(this.searchForm).then(res => {
        this.advisoryNoticePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectAdvisoryNoticeList(this.searchForm).then(res => {
        this.advisoryNoticePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectAdvisoryNoticeList(this.searchForm).then(res => {
        this.advisoryNoticePage = res
      })
    }
  }
}
</script>

<style>
</style>
